<template>
  <v-card class="ma-4" :style="(editor ? 'margin-bottom:64px;' : '') + (type === 'static' ? 'padding-bottom:1em;' : '')">
    <v-toolbar>
      <v-toolbar-title>Option List Editor: {{ name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text>Used in {{ formCount }} form{{ formCount === 1 ? '' : 's' }}</v-btn>
        </template>
        <v-list dense>
          <v-list-item v-for="({ name, link, icon }, index) in formList" :key="'form-' + index" :to="link">
            <v-list-item-title>{{ name }}</v-list-item-title>
            <v-list-item-action>
              <v-icon>{{ icon }}</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-if="formList.length === 0 && editor" @click="makeInactive">
            <v-list-item-content>
              <v-list-item-title>Inactivate this Option List</v-list-item-title>
              <v-list-item-subtitle>Since this option list is not used in any active forms, you may inactivate it.</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn outlined exact to="/forms/admin/options">Back to List</v-btn>
    </v-toolbar>
    <v-card-text v-if="type === 'static'" style="padding-bottom: 0">
      <v-alert :type="active ? 'success' : 'warning'" style="margin-bottom:1em">
        <v-row align="center">
          <v-col class="grow">Option list is currently {{ active ? 'active' : 'inactive' }}. {{ editor ? (formList.length === 0 ? 'This option list is not used in any active forms so you can inactivate it.' : 'You are an editor for this list and can make changes below.') : 'You do not have edit permission for this list.' }}</v-col>
          <v-col v-if="editor" class="shrink">
            <v-btn v-if="active && formList.length === 0" outlined @click="active ? makeInactive() : makeActive()">Make Inactive</v-btn>
            <v-btn v-else-if="!active" outlined @click="makeActive">Make Active</v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <v-textarea v-if="editor" v-model="details" label="Details about this list (purpose, etc)" rows="2" outlined></v-textarea>
      <p v-else-if="details != null && details !== ''" style="padding-bottom:9px;border-bottom:1px solid #CCC;margin-bottom:1em">List Details: {{ details }}</p>
      <v-dialog width="600">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" outlined>
            <v-icon left color="info">fas fa-question-circle</v-icon>
            Help Details
          </v-btn>
        </template>
        <v-card>
          <v-card-title>Form Options Help</v-card-title>
          <v-card-text>
            <p>The roles are determined by your current roles in the system. You can create an option list that is only available to use by certain roles or by everyone. You also need to set who can edit the list, which can be either just you or anyone who has the selected role.</p>
            <p>The "Visible Text" is what a user who submits the form will see.</p>
            <p>The "Stored Value" is whether you want to save something different than what is displayed (like connecting to a particular code in Banner or a shortened version of the visible text). If nothing is entered then it will default to the text of the visible input.</p>
            <p>The "Disabled" option is whether this selection is currently disabled in the list. It will still show in the list but will not be accessible.</p>
            <p>The "Additional Text" option holds some additional text that will show for the selected option. If this is used in a select, autocomplete, or combobox input then it will only show however much will fit on one line. If it is used with a radio group input, then all of the text will be visible in a paragraph beneath the visible text with the radio input.</p>
          </v-card-text>
        </v-card>
      </v-dialog>
      <span style="margin-left:1em">Click on this button to get more information about the information below.</span>
      <v-row style="margin-top:1em">
        <v-col cols="12" md="6">
          <v-select :items="visibleRoleItems" v-model="visibleRole" :disabled="!editor" label="What role can include this list in their form?"></v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-select :items="editRoleItems" v-model="editRole" :disabled="!editor" label="Who can edit this list?"></v-select>
        </v-col>
      </v-row>
    </v-card-text>
    <v-btn v-if="type === 'static' && editor" outlined @click="options.push({ text: '', value: '', subtext: '' })">
      <v-icon left>fal fa-plus</v-icon>
      Add Option
    </v-btn>
    <v-simple-table v-if="type === 'static'">
      <template v-slot:default>
        <thead>
          <tr>
            <th v-if="editor" style="width:50px">Reorder</th>
            <th class="text-left">Visible Text</th>
            <th class="text-left">Stored Value</th>
            <th class="text-left">Additional Text</th>
            <th class="text-left">Disabled?</th>
            <th v-if="editor" class="text-left">Edit</th>
          </tr>
        </thead>
        <draggable v-model="options" handle=".handle" tag="tbody">
          <tr v-for="(item, index) in options" :key="'row-' + index">
            <td v-if="editor" class="handle">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" style="cursor:grab;margin-top:16px">fas fa-grip-lines</v-icon>
                </template>
                <span>Drag this item to put it in a different place in the order.</span>
              </v-tooltip>
            </td>
            <td>{{ item.text }}</td>
            <td>{{ item.value }}</td>
            <td style="max-width:300px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis">{{ item.subtext }}</td>
            <td>{{ item.disabled ? 'Y' : '' }}</td>
            <td v-if="editor" style="width:45px">
              <v-dialog v-model="item.dialogOpen" width="500">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon>
                    <v-icon>fal fa-pencil</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>Editing Option</v-card-title>
                  <v-card-text>
                    <v-text-field v-model="item.text" label="Visible Text" outlined></v-text-field>
                    <v-text-field v-model="item.value" label="Stored Value" outlined></v-text-field>
                    <v-textarea v-model="item.subtext" label="Additional Text" outlined></v-textarea>
                    <v-checkbox v-model="item.disabled" label="Disabled" outlined></v-checkbox>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn text @click="finishEditingOption(item)">Done</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click="removeOption(index)">Remove</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </td>
          </tr>
        </draggable>
      </template>
    </v-simple-table>
    <v-card-text v-else>
      <v-textarea v-if="editor" v-model="details" label="Details about this list (purpose, etc)" rows="2" outlined></v-textarea>
      <p v-else style="padding-bottom:9px;border-bottom:1px solid #CCC;margin-bottom:1em">List Details: {{ details }}</p>
      <v-text-field v-model="service" label="API Service" outlined></v-text-field>
      <v-textarea v-model="serviceQuery" label="Query" outlined></v-textarea>
      <v-row>
        <v-col>
          <v-text-field v-model="textField" label="Label Field" outlined></v-text-field>
        </v-col>
        <v-col>
          <v-text-field v-model="valueField" label="Value Field" outlined></v-text-field>
        </v-col>
      </v-row>
      <v-toolbar flat>
        <v-btn outlined @click="runTest">Test Query</v-btn>
      </v-toolbar>
      <v-list dense style="overflow:auto;max-height:400px">
        <v-list-item v-for="(item, index) in dynamicOptions" :key="'dyn-option-' + index">
          <v-list-item-content>
            <v-list-item-title>{{ item[textField] }}</v-list-item-title>
            <v-list-item-subtitle v-if="item[valueField] != item[textField]">Value when selected: {{ item[valueField] }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-tooltip top v-if="editor">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" color="success" fab bottom right fixed @click="save">
          <v-icon>fal fa-save</v-icon>
        </v-btn>
      </template>
      <span>Save Changes</span>
    </v-tooltip>
  </v-card>
</template>
<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
export default {
  components: {
    draggable: () => import('vuedraggable')
  },
  setup (props, { root }) {
    const user = computed(() => 'spoof' in root.$store.state.user ? root.$store.state.user.spoof : root.$store.state.user)
    const visibleRoleItems = computed(() => {
      let arr = []
      let roleIncluded = false
      user.value.roles.forEach((role) => {
        let prefix = role.substr(0, 3)
        if (prefix !== 'ACM' && prefix !== 'VPN') arr.push({ text: role, value: role })
        if (role === editRole.value) roleIncluded = true
      })
      if (!roleIncluded) arr.push({ text: editRole.value, value: editRole.value })
      arr.sort((a, b) => a.text > b.text ? 1 : -1)
      arr.splice(0, 0, { text: 'Everyone', value: '' })
      return arr
    })
    const editRoleItems = computed(() => {
      let arr = []
      let roleIncluded = false
      user.value.roles.forEach((role) => {
        let prefix = role.substr(0, 3)
        if (prefix !== 'ACM' && prefix !== 'VPN') arr.push({ text: role, value: role })
        if (role === editRole.value) roleIncluded = true
      })
      if (!roleIncluded) arr.push({ text: editRole.value, value: editRole.value })
      arr.sort((a, b) => a.text > b.text ? 1 : -1)
      arr.splice(0, 0, { text: 'Only Me', value: '' })
      return arr
    })
    const id = computed(() => root.$route.params.id)
    const formCount = ref('')
    const formList = ref([])
    const editor = ref(false)
    const name = ref('')
    const type = ref('')
    const active = ref(true)
    const details = ref('')
    const options = ref([])
    const editRole = ref('')
    const visibleRole = ref('')

    const showDetails = ref(false)

    const service = ref('')
    const serviceQuery = ref('')
    const textField = ref('')
    const valueField = ref('')
    const dynamicOptions = ref([])

    function load () {
      root.$feathers.service('forms/select-options').get(id.value).then((data) => {
        name.value = data.name
        type.value = data.type
        active.value = 'active' in data ? data.active : true
        details.value = data.details
        visibleRole.value = data.visibleRole || ''
        editRole.value = data.editRole || ''
        if (editRole.value === '') {
          editor.value = data.createdBy === user.value.username
        } else {
          editor.value = false
          for (let i = 0; i < user.value.roles.length; i++) {
            if (user.value.roles[i] === editRole.value) editor.value = true
          }
        }
        if (data.type === 'dynamic') {
          service.value = data.service
          serviceQuery.value = data.query
          textField.value = data.textField
          valueField.value = data.valueField
        } else {
          options.value = data.options
        }
      })
      const query = {
        $or: [
          {
            'sections.inputs': {
              $elemMatch: {
                input: { $in: ['select', 'autocomplete', 'combobox'] },
                options: id.value
              }
            }
          },
          {
            'sections.inputs.inputs': {
              $elemMatch: {
                input: { $in: ['select', 'autocomplete', 'combobox'] },
                options: id.value
              }
            }
          }
        ],
        $limit: 0
      }
      root.$feathers.service('forms/revision').find({ query }).then(async ({ total }) => {
        query.$limit = 50
        const ids = []
        for (let i = 0; i < total; i += 50) {
          query.$skip = i
          const { data } = await root.$feathers.service('forms/revision').find({ query })
          data.forEach(({ _id }) => ids.push(_id))
        }
        root.$feathers.service('forms/base').find({ query: { $or: [{ 'revisions.active': { $in: ids } }, { 'revisions.editing': { $in: ids } }], $limit: 50 } }).then(({ data, total }) => {
          formList.value = data.map(({ _id, name, shortName, access }) => {
            // Determine if this user has access to edit the form
            let isEditor = false
            if ('users' in access && Array.isArray(access.users) && access.users.length > 0) {
              for (let i = 0; i < access.users.length; i++) {
                if (access.users[i].pidm === user.value.pidm) {
                  isEditor = true
                  break
                }
              }
            }
            // If so, then we add this form with an edit link
            if (isEditor) return { name, icon: 'fal fa-edit', link: '/forms/admin/' + _id }
            // If not, then we add this form with a view link
            return { name, icon: 'fal fa-eye', link: '/forms/' + shortName }
          })
          formCount.value = total
        })
      })
    }

    onMounted(() => {
      load()
    })
    watch(() => props.id, load)

    function finishEditingOption (item) {
      if (item.value == null || item.value === '') {
        item.value = item.text
      }
      item.dialogOpen = false
    }

    function removeOption (index) {
      options.value.splice(index, 1)
    }

    function move (dir, index) {
      let el = options.value.splice(index, 1)
      options.value.splice(index + dir, 0, el[0])
    }

    function save () {
      for (let i = 0; i < options.value.length; i++) {
        if (options.value[i].text.trim() === '') {
          alert('You canot save with an empty option text. Either remove the empty option or add text.')
          return
        } else if (options.value[i].value.trim() === '') {
          options.value[i].value = options.value[i].text
        }
      }
      const obj = {
        details: details.value,
        visibleRole: visibleRole.value,
        editRole: editRole.value
      }
      if (type.value === 'static') {
        obj.options = options.value
      } else if (type.value === 'dynamic') {
        obj.service = service.value
        obj.query = serviceQuery.value
        obj.textField = textField.value
        obj.valueField = valueField.value
      }
      root.$feathers.service('forms/select-options').patch(id.value, obj).then((data) => {
        root.$store.dispatch('main/snackbar', { active: true, color: 'success', timeout: 6000, text: 'Option List updated successfully' })
        root.$router.push('/forms/admin/options')
      })
    }

    function runTest () {
      let query = ''
      try {
        let dt = new Date()
        query = JSON.parse(serviceQuery.value.replace('_now_', dt.toISOString()))
      } catch (err) {
        alert('Error parsing query; make sure it is in proper JSON format.')
        return
      }
      root.$feathers.service(service.value).find({ query }).catch((err) => {
        // console.log(err)
        alert(err)
      }).then((data) => {
        if ('data' in data) dynamicOptions.value = data.data
        else dynamicOptions.value = data
      })
    }

    function makeInactive () {
      if (confirm('Are you sure you want to remove this option list?')) {
        root.$feathers.service('forms/select-options').patch(id.value, { active: false }).then((data) => {
          root.$store.dispatch('main/snackbar', { active: true, color: 'success', text: 'Option list made inactive', timeout: 6000 })
          active.value = data.active
        })
      }
    }
    function makeActive () {
      root.$feathers.service('forms/select-options').patch(id.value, { active: true }).then((data) => {
        root.$store.dispatch('main/snackbar', { active: true, color: 'success', text: 'Option list made active', timeout: 6000 })
        active.value = data.active
      })
    }

    return {
      user,
      visibleRoleItems,
      editRoleItems,
      id,
      formCount,
      formList,
      editor,
      name,
      type,
      active,
      details,
      options,
      editRole,
      visibleRole,
      showDetails,
      service,
      serviceQuery,
      textField,
      valueField,
      dynamicOptions,
      load,
      finishEditingOption,
      removeOption,
      move,
      save,
      runTest,
      makeInactive,
      makeActive
    }
  }
}
</script>
